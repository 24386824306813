// custom typefaces
import "typeface-lato"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "./src/css/@wordpress/block-library/build-style/style.css"
import "./src/css/@wordpress/block-library/build-style/theme.css"

// normalize CSS across browsers
import "./src/css/normalize.css"
import "./src/css/bootstrap.css"

// custom CSS styles
import "./src/css/style.css"
import "./src/css/master.css"
import "./src/css/customizer.css"

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onRouteUpdate = () => {
  addScript("https://code.jquery.com/jquery-3.5.1.slim.min.js")
  //addScript("https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js")
  addScript(
    "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
  )
  addScript(
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
  )
}
